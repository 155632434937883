import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section, Image } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Início | Clube de Golfe Fairway Legends
			</title>
			<meta name={"description"} content={"No Fairway Legends Golf Club, misturamos o fascínio intemporal do golfe com instalações modernas e requintadas."} />
			<meta property={"og:title"} content={"Início | Clube de Golfe Fairway Legends"} />
			<meta property={"og:description"} content={"No Fairway Legends Golf Club, misturamos o fascínio intemporal do golfe com instalações modernas e requintadas."} />
			<meta property={"og:image"} content={"https://frotpayess.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://frotpayess.com/img/45221123.jpeg"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://frotpayess.com/img/45221123.jpeg"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://frotpayess.com/img/45221123.jpeg"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://frotpayess.com/img/45221123.jpeg"} />
			<link rel={"apple-touch-startup-image"} href={"https://frotpayess.com/img/45221123.jpeg"} />
			<meta name={"msapplication-TileImage"} content={"https://frotpayess.com/img/45221123.jpeg"} />
		</Helmet>
		<Components.Header />
		<Section
			background="linear-gradient(0deg,rgba(4, 8, 12, 0.6) 0%,rgba(4, 8, 12, 0.6) 100%),--color-darkL2 url(https://frotpayess.com/img/1.jpg) center/cover"
			padding="60px 0"
			sm-padding="40px 0"
			color="--light"
			font="--base"
		>
			<Box margin="50px 0px 120px 0px" display="flex">
				<Box display="flex" padding="16px 16px 16px 16px" width="75%" md-width="100%">
					<Box display="flex" flex-direction="column">
						<Text color="--lightD2" letter-spacing="1px" text-transform="uppercase" margin="0">
							Clube de Golfe{" "}
						</Text>
						<Text as="h1" font="--headline1" md-font="--headline2" margin="10px 0px">
							Fairway Legends - Onde a tradição encontra a excelência nos greens
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0" sm-padding="40px 0">
			<Override slot="SectionContent" align-items="center" />
			<Text as="p" font="--lead" margin="20px 0 20px 0" text-align="center">
				No Fairway Legends Golf Club, misturamos o fascínio intemporal do golfe com instalações modernas e requintadas. O nosso clube é mais do que um simples campo de golfe - é um santuário para aqueles que apreciam os aspectos mais requintados do jogo. Quer seja um profissional experiente ou um novato nos greens, o Fairway Legends oferece uma experiência de golfe inigualável.
			</Text>
		</Section>
		<Section padding="120px 0 130px 0" lg-padding="80px 0 90px 0" quarkly-title="Stages/Steps-3">
			<Text
				margin="0px 0px 80px 0px"
				font="--headline2"
				color="--darkL1"
				border-color="--color-dark"
				text-align="center"
				lg-margin="0px 0px 50px 0px"
			>
				Porquê escolher o Fairway Legends Golf Club?
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				md-grid-template-columns="1fr"
				md-grid-template-rows="auto"
				md-grid-gap={0}
				padding="0px 50px 0px 50px"
				lg-padding="0px 0 0px 0"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					lg-padding="0px 25px 26px 25px"
					flex-direction="column"
					align-items="center"
					padding="0px 30px 30px 30px"
					md-padding="0px 25px 34px 25px"
					sm-padding="0px 0 34px 0"
				>
					<Box min-width="100px" min-height="100px" margin="0px 0px 0px 0">
						<Text
							margin="0px 0px 15px 0px"
							border-color="--color-light"
							color="--darkL1"
							font="normal 500 22px/1.2 --fontFamily-sans"
							text-align="center"
						>
							Desenho imaculado do campo
						</Text>
						<Text
							margin="0px 0px 0px 0px"
							font="normal 400 16px/1.5 --fontFamily-sansHelvetica"
							color="#65696d"
							text-align="center"
							border-color="#7a7c7f"
						>
							O nosso campo de 18 buracos, com um design magnífico, oferece uma experiência desafiante mas agradável para todos os níveis de aptidão.
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					lg-padding="0px 25px 26px 25px"
					flex-direction="column"
					align-items="center"
					padding="0px 30px 30px 30px"
					md-padding="0px 25px 34px 25px"
					sm-padding="0px 0 34px 0"
				>
					<Box min-width="100px" min-height="100px" margin="0px 0px 0px 0">
						<Text
							margin="0px 0px 15px 0px"
							border-color="--color-light"
							color="--darkL1"
							font="normal 500 22px/1.2 --fontFamily-sans"
							text-align="center"
						>
							Instalações de prática de última geração
						</Text>
						<Text
							margin="0px 0px 0px 0px"
							font="normal 400 16px/1.5 --fontFamily-sansHelvetica"
							color="#65696d"
							text-align="center"
							border-color="#7a7c7f"
						>
							Aprimore suas habilidades com nosso campo de prática de primeira linha, putting greens e áreas de jogo curto.
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					lg-padding="0px 25px 26px 25px"
					flex-direction="column"
					align-items="center"
					padding="0px 30px 30px 30px"
					md-padding="0px 25px 0 25px"
					sm-padding="0px 0 0 0"
				>
					<Box min-width="100px" min-height="100px" margin="0px 0px 0px 0">
						<Text
							margin="0px 0px 15px 0px"
							border-color="--color-light"
							color="--darkL1"
							font="normal 500 22px/1.2 --fontFamily-sans"
							text-align="center"
						>
							Benefícios exclusivos de membro
						</Text>
						<Text
							margin="0px 0px 0px 0px"
							font="normal 400 16px/1.5 --fontFamily-sansHelvetica"
							color="#65696d"
							text-align="center"
							border-color="#7a7c7f"
						>
							Desfrute de privilégios especiais, acesso a eventos e uma comunidade de colegas entusiastas do golfe.
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-2">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				display="flex"
				justify-content="center"
				lg-width="100%"
				align-items="flex-start"
				lg-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				width="75%"
			>
				<Box
					height="auto"
					overflow-x="hidden"
					overflow-y="hidden"
					hover-transform="translateY(-10px)"
					padding="0px 0px 65% 0px"
					width="100%"
					position="relative"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
				>
					<Image
						position="absolute"
						display="block"
						right={0}
						min-height="100%"
						bottom={0}
						src="https://frotpayess.com/img/2.jpg"
						object-fit="cover"
						width="100%"
						top={0}
						left={0}
					/>
				</Box>
			</Box>
			<Box
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				lg-order="1"
				justify-content="space-between"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				display="flex"
				width="25%"
				flex-direction="column"
				align-items="flex-start"
				lg-width="100%"
			>
				<Text
					margin="0px 0px 40px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					md-text-align="left"
				>
					Instrução profissional: Os nossos instrutores certificados pela PGA estão à disposição para melhorar o seu jogo, desde ajustes de técnica a revisões completas.
				</Text>
				<Box
					height="243px"
					overflow-x="hidden"
					position="relative"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					width="243px"
					overflow-y="hidden"
					border-radius="100%"
				>
					<Image
						right={0}
						bottom={0}
						min-height="100%"
						src="https://frotpayess.com/img/3.jpg"
						object-fit="cover"
						width="100%"
						left={0}
						position="absolute"
						display="block"
						top={0}
					/>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});